import { Grid } from '@achieve/ascend'
import HeaderCta from './HeaderCta'
import { SignInLink } from 'components/SignInLink'
import { SIGN_IN_ROUTE } from 'constants/navigation'
import styles from './Header.module.scss'

export default function HeaderMobileActions({
  disabledRoutes = [],
  headerCta = true,
  signInLinkText,
  signInLinkUrl,
  getStartedLinkText,
  getStartedLinkUrl,
}) {
  return (
    <Grid container className={styles['mobile-header-actions']} justifyContent="center">
      {!disabledRoutes.includes(SIGN_IN_ROUTE) && (
        <Grid
          item
          container
          className={styles['mobile-header-actions-sign-in-link-container']}
          alignItems="center"
          justifyContent="center"
          xs={headerCta ? 6 : 12}
        >
          <Grid item alignItems="center" justifyContent="center">
            <SignInLink
              disabledRoutes={disabledRoutes}
              data-testid="mobile-nav-sign-in-link"
              className={styles['mobile-header-actions-sign-in-link']}
              signInLinkText={signInLinkText}
              signInLinkUrl={signInLinkUrl}
            />
          </Grid>
        </Grid>
      )}
      <Grid xs={6} item>
        {headerCta && (
          <HeaderCta
            className={styles['mobile-header-actions-cta']}
            size="small"
            data-testid="mobile-header-actions-get-started-button"
            linkUrl={getStartedLinkUrl}
            linkText={getStartedLinkText}
          />
        )}
      </Grid>
    </Grid>
  )
}
